import firebase from "firebase/app";
import React, { FC, useState, useEffect } from "react";
import { Container, Modal, Button } from "react-bootstrap";

import { DatabaseNode } from "../../database";
import Form from "./components/Form";
import ListData from "./components/ListData";
import { cssUMWrapper, cssContainer } from "./style";

type ListProps = {
  [key: string]: any
}

const UserManagement: FC = () => {
  const [list, setList] = useState<ListProps>({});
  const [show, setShow] = useState(false);
  const [keyData, setKeyData] = useState("");

  const getData = () => {
    firebase
      .database()
      .ref(DatabaseNode.accounts(firebase.auth().currentUser?.uid || ''))
      .on("value", (snapshot) => {
        const value = snapshot.val()
        if (value) {
          const keys = Object.keys(value);
          const temp: ListProps = {};
          keys.forEach((v: any) => {
            if (!value[v].deletedAt) {
              temp[v] = value[v];
            }
          });
          setList(temp);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdateList = (email: string) => {
    const keys = Object.keys(list);

    let isDataExist = false;
    keys.forEach((v: any) => {
      if (list[v].email === email && !list[v].deletedAt) {
        isDataExist = true;
        return;
      }
    });

    if (isDataExist) {
      alert("Data sudah ada");
      return false;
    }

    firebase
      .database()
      .ref(DatabaseNode.accounts(firebase.auth().currentUser?.uid || ''))
      .push({
        email,
        createdAt: new Date().getTime(),
      })
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });

    getData();
  };

  const handleDeleteData = (key: string) => {
    setKeyData(key);
    setShow(true);
  };

  const submitDelete = () => {
    firebase
      .database()
      .ref(DatabaseNode.accountDetail(firebase.auth().currentUser?.uid || '', keyData || ''))
      .update({
        deletedAt: new Date().getTime(),
      });

    handleCloseModal();
    getData();
  };

  const handleCloseModal = () => {
    setKeyData("");
    setShow(false);
  };

  return (
    <div className={cssUMWrapper}>
      <Container className={cssContainer}>
        <Form handleUpdateList={handleUpdateList} />
        <ListData list={list} handleDeleteData={handleDeleteData} />
        <Modal show={show} onHide={handleCloseModal}>
          <Modal.Body>
            <b>Apakah Anda yakin ingin menghapus pengguna ini??</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={submitDelete}>
              Ya
            </Button>
            <Button variant="success" onClick={handleCloseModal}>
              Tidak
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default UserManagement;
