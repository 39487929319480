import React, { FC } from "react";
import { Container } from "react-bootstrap";

import Form from "./components/Form";

import { cssTopHomeWrapper, cssBox, cssTitle, cssDesc } from "./style";

const Home: FC = () => (
  <div className={cssTopHomeWrapper}>
    <Container>
      <div className={cssBox}>
        <div className={cssTitle}>Selamat Datang di databocor</div>
        <div className={cssDesc}>
          Cari email atau domain anda, dan cari tahu apa yang sudah diketahui peretas
          tentang anda.
        </div>
        <Form />
      </div>
    </Container>
  </div>
);

export default Home;