import React, { FC } from "react";
import { ListGroup } from "react-bootstrap";

import { cssListWrapper, cssValue, cssTitle } from "./style";

const ListComponent: FC<any> = ({ list }) => {
  return (
    <ListGroup className={cssListWrapper}>
      {Object.keys(list).map((valKey, index) => {
        const value = list[valKey];

        return (
          <ListGroup.Item key={index}>
            <div className={cssTitle}>Email</div>
            <div className={cssValue}>{value.email}</div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default ListComponent;
