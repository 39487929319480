import { css } from "react-emotion";

export const cssHistory = css({
  marginTop: 32,
  textAlign: "center",
  borderRadius: 8,
  thead: {
    background: "#0d6efd !important",
    color: "#fff",
  },
  tbody: {
    background: "#fff",
    tr: {
      ":hover": {
        backgroundColor: "rgb(209 227 255) !important",
      },
    },
  },
});

export const cssListWrapper = css({
  margin: "18px 0px",
  ".list-group-item": {
    margin: "8px 0px",
    maxWidth: 300,
  },
});

export const cssTitle = css({
  lineHeight: 1.5,
  fontSize: 12,
  color: "#5b5b5b",
  margin: "4px 0px",
});

export const cssValue = css({
  maxWidth: 300,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  lineHeight: 1.5,
  fontSize: 14,
  fontWeight: 500,
  color: "#20123a",
});
