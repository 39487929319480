import React, { FC, useLayoutEffect, useState } from "react";

import ListComponent from "./mobile";
import TableComponent from "./desktop";

interface Props {
  list: any;
  handleDeleteData: (param: string) => void;
}

const ListDataComponent: FC<Props> = ({ list, handleDeleteData }) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setIsMobile(false);
      else setIsMobile(true);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? (
    <ListComponent list={list} />
  ) : (
    <TableComponent list={list} handleDeleteData={handleDeleteData} />
  );
};

export default ListDataComponent;
