import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

import { checkEmailValid } from "../../../helpers/func";
import {
  cssFormWrapper,
  cssFormFooterNotes,
  cssButton,
  cssTextfield,
} from "../style";

const TopForm: FC = () => {
  const history = useHistory();
  const [isValid, setIsValid] = useState(true);

  const handleSubmitForm = (event: any) => {
    const form = event.currentTarget;
    const emailValue = form.elements["formEmail"].value;

    if (emailValue === "" || !checkEmailValid(emailValue)) {
      event.preventDefault();
      event.stopPropagation();
      setIsValid(false);
      return;
    }

    localStorage.setItem("scanEmail", emailValue);
    history.push("/search");
  };

  return (
    <Form className={cssFormWrapper} onSubmit={handleSubmitForm}>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Control
          type="text"
          placeholder="Masukkan Email atau Domain"
          size="lg"
          className={cssTextfield(isValid)}
        />
      </Form.Group>
      <Button variant="primary" type="submit" size="lg" className={cssButton}>
        <b>Periksa Pelanggaran</b>
      </Button>
      <p className={cssFormFooterNotes}>
        Cari alamat email Anda dalam pelanggaran data publik.
      </p>
    </Form>
  );
};

export default TopForm;
