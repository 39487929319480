import { css } from "react-emotion";
import { mediaTo, mediaFrom, breakpoints } from "../../../../helpers/styles";

const { md } = breakpoints;

export const cssPurpleColor = {
  color: "#9059ff",
};

export const cssInfoWrapper = css({
  maxWidth: 860,
  padding: "0px 36px",
  textAlign: "center",
  overflowWrap: "break-word",
  color: "#000000",
  [mediaTo(md)]: { textAlign: "left" },
});

export const cssResultTxt = css({
  fontSize: 24,
  fontWeight: 600,
});

export const cssResultEmailTxt = css({
  ...cssPurpleColor,
  fontWeight: 700,
});

export const cssResultDesc = css({
  maxWidth: 600,
  fontSize: 36,
  marginBottom: 18,
  lineHeight: 1.2,
  [mediaFrom(md)]: {
    fontSize: 40,
  },
});
