import React, { FC } from "react";

import * as sty from "./style";

interface Props {
  length: number;
  type: string;
  valueDetail: string;
}

const InfoComponent: FC<Props> = ({ length, type, valueDetail }) => {
  let data = "";
  if (valueDetail !== "") {
    data = valueDetail;
  } else {
    data = localStorage.getItem("scanEmail") || "-";
  }

  return (
    <div className={sty.cssInfoWrapper}>
      <p className={sty.cssResultTxt}>
        Hasil pencarian untuk: <span className={sty.cssResultEmailTxt}>{data}</span>
      </p>
      <div className={sty.cssResultDesc}>
        <b>
          {type} ini muncul di{" "}
          <span style={{ ...sty.cssPurpleColor }}>{length}</span> dalam pelanggaran data yang diketahui.
        </b>
      </div>
    </div>
  );
};

export default InfoComponent;
