import { css } from "react-emotion";

export const cssScanWrapper = css({
  minHeight: "100vh",
  background: "#f9f7fd",
  paddingTop: "5rem",
  paddingBottom: 48,
});

export const cssContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const cssCardsWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginTop: 24,
});

export const cssProvidedTxt = css({
  marginTop: 18,
  color: "#5e5e72",
  fontSize: 14,
});

export const cssButton = css({
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 8,
  width: 200,
  background: "#ff9900 !important",
  borderColor: "#ff9900 !important",
  ":disabled": {
    background: "#adabb1 !important",
    borderColor: "#adabb1 !important",
    cursor: "not-allowed",
  },
});

export const cssSpinner = css`
  margin-top: 24px;
  width: 50px;
  height: 50px;
  display: grid;
  color: #854f1d;
  background: radial-gradient(
    farthest-side,
    currentColor calc(100% - 6px),
    #0000 calc(100% - 5px) 0
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 13px),
    #000 calc(100% - 12px)
  );
  border-radius: 50%;
  animation: s9 2s infinite linear;

  ::before {
    content: "";
    grid-area: 1/1;
    background: linear-gradient(currentColor 0 0) center,
      linear-gradient(currentColor 0 0) center;
    background-size: 100% 10px, 10px 100%;
    background-repeat: no-repeat;
  }

  ::after {
    content: "";
    grid-area: 1/1;
    background: linear-gradient(currentColor 0 0) center,
      linear-gradient(currentColor 0 0) center;
    background-size: 100% 10px, 10px 100%;
    background-repeat: no-repeat;
    transform: rotate(45deg);
  }

  @keyframes s9 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
