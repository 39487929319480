import firebase from "firebase/app";
import { FC, FormEvent, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Style from "./style.module.css";
import FacebookLogo from "../../assets/brands/facebook.png";
import GoogleLogo from "../../assets/brands/google.png";
import TwitterLogo from "../../assets/brands/twitter.png";
import imgHomeBG from "../../assets/def-bg.jpeg";
import { DatabaseNode, UsersType } from "../../database";
import { ScreenPaths } from "../../routing";

type Props = {};

const LoginScreen: FC<Props> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const updateProfile = (userCredential: firebase.auth.UserCredential) => {
    const auth = userCredential?.user;

    const user: UsersType = {
      email: auth?.email,
      phone: auth?.phoneNumber,
    };

    firebase
      .database()
      .ref(DatabaseNode.userDetail(auth?.uid || ""))
      .set(user)
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  };

  const handleEmailSignIn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    firebase.app().auth().signInWithEmailAndPassword(email, password);
  };

  const handleFacebookSignIn = () => {
    const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
    firebase
      .app()
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then(updateProfile)
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  };

  const handleGoogleSignIn = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    firebase
      .app()
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(updateProfile)
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  };

  const handleTwitterSignIn = () => {
    const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
    firebase
      .app()
      .auth()
      .signInWithPopup(twitterAuthProvider)
      .then(updateProfile)
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${imgHomeBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: "3rem",
      }}
    >
      <Container>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 6 }}>
            <h1 className="mb-5">Sign in</h1>
            <Form onSubmit={handleEmailSignIn}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Append onClick={() => setShowPassword(!showPassword)}>
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        color="black"
                        size="1x"
                        fixedWidth
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox" className="my-4">
                <Form.Check type="checkbox" label="Ingat saya" />
              </Form.Group>
              <Button variant="primary" type="submit" className="px-5">
                Log in
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="pt-2 pb-5" xs={12} md={{span: 6, offset: 6}}>
            <label>atau Login menggunakan</label>
            <img
              src={FacebookLogo}
              alt="Facebook Login"
              className={Style.icon}
              onClick={handleFacebookSignIn}
            />
            <img
              src={GoogleLogo}
              alt="Google Login"
              className={Style.icon}
              onClick={handleGoogleSignIn}
            />
            <img
              src={TwitterLogo}
              alt="Twitter Login"
              className={Style.icon}
              onClick={handleTwitterSignIn}
            />
          </Col>
        </Row>
        <Row>
          <Col className="pb-5" xs={12} md={{span: 6, offset: 6}}>
            <NavLink to={ScreenPaths.register}>Buat Akun</NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginScreen;