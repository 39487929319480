import { FC } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import { NavLink } from "react-router-dom";
import { ScreenPaths } from "../../routing";
import databocorLogo from "../../assets/brands/databocor.png";

type Props = {};

const Link: FC<{ to: string; label: string; exact?: boolean }> = ({
  to,
  label,
  exact,
}) => {
  return (
    <NavLink to={to} exact={exact} className="nav-link">
      {label}
    </NavLink>
  );
};

const LinkButton: FC<{
  to: string;
  label: string;
  exact?: boolean;
  variant?: ButtonVariant;
}> = ({ to, label, exact, variant }) => {
  return (
    <NavLink to={to} exact={exact} className="float-right">
      <Button variant={variant}>{label}</Button>
    </NavLink>
  );
};

const Anonymous: FC<Props> = () => {
  return (
    <Navbar variant="dark" expand="lg">
      <NavLink to="/">
        {/* <Navbar.Brand>databocor</Navbar.Brand> */}
        <img alt="" src={databocorLogo} width="60" height="60" className="d-inline-block align-top"/>
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link exact to={ScreenPaths.home} label="Home" />
          <Link to={ScreenPaths.search} label="Search" />
          <Link to={ScreenPaths.accountManagemet} label="Manajemen Akun" />
          <Link to={ScreenPaths.privacyPolicy} label="Kebijakan Privasi" />
        </Nav>
        <LinkButton
          to={ScreenPaths.logout}
          variant="outline-light"
          label="Logout"
        />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Anonymous;
