const CHECK_DOMAIN =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

const CHECK_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkEmailValid = (email: string) => {
  const check = CHECK_DOMAIN.test(email) || CHECK_EMAIL.test(email);
  return check;
};

export const checkInputType = (email: string) => {
  if (CHECK_DOMAIN.test(email)) return "domain";

  if (CHECK_EMAIL.test(email)) return "email";

  return "";
};
