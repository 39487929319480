import { css } from "react-emotion";

export const cssUMWrapper = css({
  background: "#f9f7fd",
  paddingTop: "5rem",
  paddingBottom: 48,
  height: "calc(100vh - 56px)",
});

export const cssContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
