import React, { FC } from "react";
import { Container } from "react-bootstrap";

import { cssPPWrapper, cssContainer, cssTitle, cssSubTitle } from "./style";

const PrivacyPolicy: FC = () => (
  <div className={cssPPWrapper}>
    <Container className={cssContainer}>
      <div className={cssTitle}>
        <b>KEBIJAKAN PRIVASI</b>
      </div>
      <div style={{ textAlign: "left" }}>
        <p>
            Kebijakan privasi ditetapkan oleh{" "}
          <a href="https://www.databocor.com/" target="blank">
            databocor
          </a>{" "}
            . Dengan mengunjungi situs ini anda setuju untuk menentukan syarat dan ketentuan
            kebijakan privasi ini ditetapkan oleh kami. Kami dapat mengubah persyaratan ini dan
            kondisi dari waktu ke waktu, dan karena itu anda diharapkan untuk memeriksa
            secara berkala.
        </p>
        <p>
          Data yang diperlukan untuk{" "}
          <a href="https://www.databocor.com/" target="blank">
            databocor
          </a>{" "}
            . Kami akan menyimpan informasi tentang hasil pencarian yang anda lakukan ketika
            memeriksa kebocoran data. Kami akan menyimpan informasi alamat email anda
            ketika anda mendaftar di situs ini untuk memberikan hasil pencarian terbaru
            dan mengirimkannya melalui email. Bahwa semua informasi yang anda berikan adalah
            disimpan dengan sebaik-baiknya dengan memperhatikan faktor keamanan.
        </p>
        <p className={cssSubTitle}>
          <b>Alamat Email</b>
        </p>
        <ul>
          <li>
            Kami akan menyimpan informasi tentang hasil pencarian yang anda lakukan ketika
            memeriksa kebocoran data.
          </li>
          <li>
            Kami akan menyimpan informasi alamat email anda saat anda mendaftar di
            situs ini untuk memberikan hasil pencarian terbaru dan mengirimkannya melalui
            email.
          </li>
          <li>
            Bahwa semua informasi yang anda berikan akan disimpan juga
            dimungkinkan dengan memperhatikan faktor keamanan.
          </li>
        </ul>
        <p className={cssSubTitle}>
          <b>Keamanan</b>
        </p>
        <p>
          Kami berkomitmen untuk memastikan bahwa informasi yang anda berikan di {" "}
          <a href="https://www.databocor.com/" target="blank">
            databocor
          </a>{" "}
            aman. Untuk mencegah akses yang tidak sah, kami melakukan pengamanan
            sesuai dengan standar dan prosedur umum yang diperlukan untuk melindungi
            informasi anda yang kami kumpulkan.
        </p>
        <p className={cssSubTitle}>
          <b>Koneksi ke Situs Lain</b>
        </p>
        <p>
          Situs
          <a href="https://www.databocor.com/" target="blank">
            &nbsp;databocor
          </a>{" "}
            dapat menyediakan tautan ke situs web pihak ketiga lainnya. Ketika anda mengunjungi
            situs tersebut, kami tidak bertanggung jawab atas informasi apa pun yang anda berikan
            di sana. Setiap situs memiliki kebijakan privasinya sendiri dan kami tidak bertanggung jawab
            untuk konten situs tersebut. Karena itu, anda harus berhati-hati
            dan pelajari pernyataan kebijakan privasi yang berlaku untuk situs tersebut sebelumnya
            berselancar di situs tersebut.
        </p>
        <p>
            Entitas apa pun yang anda pilih sebagai kontak atau berinteraksi, baik
            direkam dalam direktori atau di tempat lain selain situs {" "}
          <a href="https://www.databocor.com/" target="blank">
            databocor
          </a>{" "}
            , bertanggung jawab penuh atas layanan kepada anda, dan setuju bahwa 
          <a href="https://www.databocor.com/" target="blank">
            &nbsp;databocor
          </a>{" "}
            tidak dapat dikenakan tindakan apapun atas kerugian atau biaya yang timbul
            dari interaksi yang terjadi.
        </p>
      </div>
    </Container>
  </div>
);

export default PrivacyPolicy;