import { FC } from "react";
import { useLocation } from "react-router-dom";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { AuthEmission } from "@react-firebase/auth/dist/types";
import "./App.css";
import Routing from "./routing";
import Theme from "./theme";

type Props = {};

const App: FC<Props> = () => {
  const { pathname } = useLocation();

  if (pathname !== "/search") {
    localStorage.removeItem("scanEmail");
  }

  return (
    <div style={style.container}>
      <FirebaseAuthConsumer>
        {({ isSignedIn }: AuthEmission) => {
          if (isSignedIn === true) {
            return <Routing.Authorized />;
          } else {
            return <Routing.Anonymous />;
          }
        }}
      </FirebaseAuthConsumer>
    </div>
  );
};

const style = {
  container: {
    background: Theme.BackgroundPrimary,
    color: Theme.ColorPrimary,
    minHeight: "100vh",
  },
};

export default App;
