import React, { FC } from "react";
import { ListGroup } from "react-bootstrap";

import { cssListWrapper, cssValue, cssTitle } from "./style";
import { ListProps } from "../../interface";

const ListComponent: FC<any> = ({ list }) => (
  <ListGroup className={cssListWrapper}>
    adsdas
    {Object.keys(list).map((valKey: string, index) => {
      const value = (list as ListProps)[valKey] as ListProps;

      return (
        <ListGroup.Item key={index}>
          <div className={cssTitle}>Email/Domain</div>
          <div className={cssValue}>{value?.email || ""}</div>
          <div className={cssTitle}>Tanggal Pencarian</div>
          <div className={cssValue}>{value?.searchDate || ""}</div>
        </ListGroup.Item>
      );
    })}
  </ListGroup>
);

export default ListComponent;
