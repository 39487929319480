import React, { FC } from "react";
import { Table, Button } from "react-bootstrap";

import { cssHistory } from "./style";
import { ListProps } from "../../interface";

interface Props {
  list: any;
  handleChangeToDetail: (value: string, response: string) => void;
}

const TableComponent: FC<Props> = ({ list, handleChangeToDetail }) => (
  <Table bordered hover className={cssHistory}>
    <thead>
      <tr>
        <th>#</th>
        <th>Email/Domain</th>
        <th>Tanggal Pencarian</th>
        <th>Tindakan</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(list || []).length > 0 ? (
        <>
          {Object.keys(list)
            .reverse()
            .map((valKey: string, index) => {
              const value = (list as ListProps)[valKey] as ListProps;
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{value.email}</td>
                  <td>{value.searchDate}</td>
                  <td>
                    <Button
                      variant="primary"
                      type="submit"
                      size="sm"
                      onClick={() =>
                        handleChangeToDetail(value.email, value.response)
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
        </>
      ) : (
        <tr>
          <td colSpan={4}>Tidak ada data</td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default TableComponent;
