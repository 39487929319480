import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";

import History from "../History";
import { HistoryProps } from "../../interface";
import { cssModal } from "./style";

const HistoryModal: FC<HistoryProps> = ({
  show,
  handleClose,
  list,
  handleChangeToDetail,
}) => {
  const [isDetail, setIsDetail] = useState(false);

  const handleCloseModal = () =>
    isDetail ? setIsDetail((prev) => !prev) : handleClose();

  return (
    <Modal show={show} onHide={handleClose} dialogClassName={cssModal}>
      <Modal.Body>
        <History list={list} handleChangeToDetail={handleChangeToDetail} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseModal}>
          {!isDetail ? "Close" : "Close Detail"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HistoryModal;
