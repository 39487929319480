import { css } from "react-emotion";
import { mediaTo, mediaFrom, breakpoints } from "../../../../helpers/styles";

const { sm, md } = breakpoints;

export const cssTopHomeWrapper = css({
  minHeight: "90vh",
  backgroundColor: "#1d1133",
  position: "relative",
  paddingTop: "5rem",
  [mediaFrom(md)]: { paddingTop: "10rem" },
});

export const cssBox = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  margin: "0px 1.5rem",
  flexDirection: "column",
  textAlign: "center",
  [mediaTo(sm)]: { textAlign: "left" },
});

export const cssTitle = css({
  maxWidth: 750,
  color: "#f9f9fa",
  fontSize: 36,
  fontWeight: 700,
  lineHeight: 1.14,
  margin: "0px auto 24px",
  [mediaFrom(md)]: { fontSize: 56 },
});

export const cssDesc = css({
  maxWidth: 560,
  color: "#f9f9fa",
  fontSize: 16,
  lineHeight: 1.17,
  margin: "0px auto 32px",
  [mediaFrom(md)]: { fontSize: 24 },
});

export const cssButton = css({
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 8,
  width: 200,
  backgroundColor: "#ff9900 !important",
  borderColor: "#ff9900 !important",
});

export const cssTextfield = (isValid: boolean): string =>
  css({
    color: "#20123a",
    fontSize: "14px !important",
    minWidth: 300,
    ...(!isValid && {
      border: "3px solid #b95252",
    }),
    [mediaFrom(md)]: {
      fontSize: "18px !important",
    },
  });

export const cssFormGroupTextField = css({
  marginBottom: "0px !important",
});

export const cssButtonWrapper = css({
  textAlign: "center",
  marginTop: "1rem",
  [mediaFrom(md)]: {
    marginTop: 0,
  },
});
