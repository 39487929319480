import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";

import { Breach } from "../../interface";
import * as sty from "./style";

const CardComponent: FC<Breach> = ({
  LogoPath,
  Name,
  BreachDate,
  DataClasses,
}) => (
  <Row className={sty.cssBox}>
    <Col lg={2}>
      <img src={LogoPath} width="24" height="24" alt="icon-img" />
    </Col>
    <Col lg={10}>
      <div className={sty.cssTitle}>{Name}</div>
      <div className={sty.cssKey}>Breach added:</div>
      <div className={sty.cssValue}>
        {dayjs(BreachDate).format("MMM DD, YYYY")}
      </div>
      <div className={sty.cssKey}>Compromised data:</div>
      <div className={sty.cssValue}>
        {DataClasses.map((val: string, index: number) => {
          if (index + 1 === DataClasses.length) return val;
          return `${val}, `;
        })}
      </div>
    </Col>
  </Row>
);

export default CardComponent;
