const ScreenPaths = {
    home: '/',
    accountManagemet: '/account-management',
    login: '/login',
    logout: '/logout',
    privacyPolicy: '/privacy-policy',
    register: '/register',
    search: '/search',
}

export default ScreenPaths
