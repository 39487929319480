import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScreenPaths from "./screen-paths";
import Navbar from "../components/navbar";
import {
  LogoutScreen,
  PageNotFoundScreen,
  UserManagementScreen,
  Scan,
  Home,
  PrivacyPolicy,
} from "../screens";

type Props = {};

const AuthorizedRouting: FC<Props> = () => {
  return (
    <>
      <Navbar.Authorized />
      <Switch>
        <Route path={ScreenPaths.home} exact component={Home} />
        <Route
          path={ScreenPaths.accountManagemet}
          component={UserManagementScreen}
        />
        <Route
          path={ScreenPaths.login}
          component={() => <Redirect to={ScreenPaths.home} />}
        />
        <Route path={ScreenPaths.logout} component={LogoutScreen} />
        <Route path={ScreenPaths.privacyPolicy} component={PrivacyPolicy} />
        <Route
          path={ScreenPaths.register}
          component={() => <Redirect to={ScreenPaths.home} />}
        />
        <Route path={ScreenPaths.search} component={Scan} />
        <Route component={PageNotFoundScreen} />
      </Switch>
    </>
  );
};

export default AuthorizedRouting;
