import firebase from 'firebase/app'
import { FC, FormEvent, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Style from './style.module.css'
import BackgroundNoodle from '../../assets/background-noodle-top2.svg'
import { DatabaseNode, UsersType } from '../../database'
import { DateFormat } from '../../utils/date'

type Props = {}

const RegisterScreen: FC<Props> = () => {
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date())
    const [gender, setGender] = useState<'M' | 'F'>('M')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const handleRegister = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (email === '') {
            alert('Email harus diisi')
            return
        }

        if (phone === '') {
            alert('Phone harus diisi')
            return
        }

        if (password === '') {
            alert('Password harus diisi')
            return
        }

        if (password !== confirmPassword) {
            alert('Password dan Konfirmasi Password harus sama')
            return
        }

        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var auth = userCredential.user
                
                if (auth?.uid) {
                    const user: UsersType = {
                        firstName: firstName,
                        lastName: lastName,
                        dateOfBirth: dateOfBirth,
                        gender: gender,
                        email: email,
                        phone: phone,
                    }
                    firebase
                        .database()
                        .ref(DatabaseNode.userDetail(auth.uid))
                        .set(user)
                }
            })
            .catch((err) => {
                console.error(err)
                alert(err.message)
            })
    }
    return (
        <>
            <img src={BackgroundNoodle} alt="Background" className={Style.noodle} />
            <Container className="mt-5">
                <Row>
                    <Col md={{ span: 6, offset: 6 }}>
                        <h1 className="mb-5">Register</h1>
                        <Form onSubmit={handleRegister}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label>Nama Awal</Form.Label>
                                        <Form.Control value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formLastName">
                                        <Form.Label>Nama Akhir</Form.Label>
                                        <Form.Control value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formBirthDay">
                                        <Form.Label>Tanggal Lahir</Form.Label>
                                        <Form.Control type="date" value={DateFormat(dateOfBirth, 'yyyy-MM-dd')} onChange={e => setDateOfBirth(new Date(e.target.value))} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Label>Jenis Kelamin</Form.Label>
                                    <Form.Group controlId="formGender" className="pt-2">
                                        <Form.Check inline name="gender" id="gender-male" label="Pria" type="radio" checked={gender === 'M'} value="M" onClick={() => setGender('M')} />
                                        <Form.Check inline name="gender" id="gender-female" label="Wanita" type="radio" checked={gender === 'F'} value="F" onClick={() => setGender('F')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formPhone">
                                        <Form.Label>Handphone</Form.Label>
                                        <Form.Control type="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="formConfirmPassword">
                                        <Form.Label>Konfirmasi Password</Form.Label>
                                        <Form.Control type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit" className="my-4 px-5">
                                Daftar
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RegisterScreen