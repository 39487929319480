import { css } from "react-emotion";

const DEFAULT_PURPLE = "#20123a";

export const cssBox = css({
  borderRadius: 8,
  background: "#fff",
  color: DEFAULT_PURPLE,
  padding: 20,
  margin: 8,
  maxWidth: 400,
});

export const cssTitle = css({
  fontSize: 18,
  fontWeight: 600,
  color: DEFAULT_PURPLE,
});

export const cssKey = css({
  marginTop: 8,
  lineHeight: 1.5,
  fontSize: 12,
  color: "#5b5b5b",
});

export const cssValue = css({
  lineHeight: 1.5,
  fontSize: 14,
  fontWeight: 500,
  color: DEFAULT_PURPLE,
});

export const cssLink = css({
  marginTop: 12,
  color: "#0060df",
  fontWeight: 600,
});
