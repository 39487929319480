export type ThemeType = {
    BackgroundPrimary: string
    ColorPrimary: string
}

const Theme: ThemeType = {
    // BackgroundPrimary: '#1d1133',
    BackgroundPrimary: 'rgb(8 70 142)',
    ColorPrimary: '#ffffff',
}

export default Theme
