import React, { FC, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { checkEmailValid } from "../../../../helpers/func";
import {
  cssButton,
  cssTextfield,
  cssFormGroupTextField,
  cssButtonWrapper,
} from "./style";

interface Props {
  handleUpdateList: (email: string) => void;
}

const FormComponent: FC<Props> = ({ handleUpdateList }) => {
  const [isValid, setIsValid] = useState(true);

  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    const emailValue = form.elements["formEmail"].value;

    if (emailValue === "" || !checkEmailValid(emailValue)) {
      event.stopPropagation();
      setIsValid(false);
      return;
    }

    setIsValid(true);
    handleUpdateList(form.elements["formEmail"].value);
    form.elements["formEmail"].value = "";
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <Form.Group controlId="formEmail" className={cssFormGroupTextField}>
            <Form.Control
              type="text"
              placeholder="Masukkan Email atau Domain"
              size="sm"
              className={cssTextfield(isValid)}
            />
          </Form.Group>
        </Col>
        <Col className={cssButtonWrapper}>
          <Button
            variant="primary"
            type="submit"
            size="lg"
            className={cssButton}
          >
            Submit Email
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
