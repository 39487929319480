import Anonymous from './anonymous'
import Authorized from './authorized'
import ScreenPaths from './screen-paths'

const Routing = {
    Anonymous,
    Authorized,
}

export { ScreenPaths }

export default Routing
