import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import dayjs from "dayjs";

import { checkInputType } from "../../helpers/func";
import { DatabaseNode } from "../../database";
import { Breach } from "./interface";
import { API_URL_BREACHEDAC_COUNT, API_URL_BREACHES } from "./config";
import InfoComponent from "./components/Info";
import CardComponent from "./components/Card";
import Modal from "./components/Modal";

import {
  cssScanWrapper,
  cssContainer,
  cssCardsWrapper,
  cssButton,
  cssSpinner,
} from "./style";

const ScanComponent = () => {
  const [list, setList] = useState<Breach[]>([]);
  const [listHistory, setListHistory] = useState();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkInput, setCheckInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [valueDetail, setValueDetail] = useState("");

  const user_uid = firebase.auth().currentUser?.uid || "";

  useEffect(() => {
    const value = localStorage.getItem("scanEmail") || "";
    const getData = async () => {
      if (value !== "") {
        const check = checkInputType(value);
        setCheckInput(check);
        if (check !== "") {
          await fetch(
            check === "domain"
              ? API_URL_BREACHES(value)
              : API_URL_BREACHEDAC_COUNT(value)
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.type) {
                alert(data.message);
              } else {
                setList(data || []);
                if (user_uid !== "") {
                  firebase
                    .database()
                    .ref(DatabaseNode.history(user_uid || ""))
                    .push({
                      email: value,
                      response: JSON.stringify(data),
                      searchDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    })
                    .catch((err) => {
                      console.error(err);
                      alert(err.message);
                    });
                }
              }
            })
            .catch((err) => {
              console.error(err);
              alert(err.message);
            })
            .finally(() => setLoading(false));
        }
      } else {
        setShowModal(true);
      }
    };

    const fetchHistory = () => {
      if (user_uid !== "") {
        firebase
          .database()
          .ref(DatabaseNode.history(user_uid || ""))
          .on("value", (snapshot) => {
            setListHistory(snapshot.val());
            if (value === "") setLoading(false);
          });
      }
    };

    getData();

    if (user_uid !== "") fetchHistory();
  }, [user_uid]);

  const handleChangeToDetail = (value: string, response: string) => {
    const tempResp = JSON.parse(response);
    setList(tempResp || []);
    setValueDetail(value);
    setShowModal(false);
  };

  return (
    <div className={cssScanWrapper}>
      <Container className={cssContainer}>
        <InfoComponent
          length={list.length}
          type={checkInput}
          valueDetail={valueDetail}
        />
        {loading ? (
          <div className={cssSpinner} />
        ) : (
          <>
            <div className={cssCardsWrapper}>
              {list.map((val, index) => (
                <CardComponent key={index} {...val} />
              ))}
            </div>
            {user_uid !== "" && (
              <>
                <div style={{ textAlign: "center", marginTop: 18 }}>
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className={cssButton}
                    onClick={() => setShowModal((prev) => !prev)}
                    disabled={Object.keys(listHistory || {}).length === 0}
                  >
                    Search History
                  </Button>
                </div>
                <Modal
                  show={showModal}
                  handleClose={() => setShowModal((prev) => !prev)}
                  list={listHistory}
                  handleChangeToDetail={handleChangeToDetail}
                />
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default ScanComponent;
