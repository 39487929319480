import { FC } from 'react'

type Props = {}

const PageNotFoundScreen: FC<Props> = () => {
    return (
        <h1 className="text-center mt-5">Halaman Tidak Ditemukan</h1>
    )
}

export default PageNotFoundScreen