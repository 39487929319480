export type ConfigType = {
    env: string
    firebase: {
        apiKey: string
        authDomain: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
        measurementId: string
        databaseURL: string
    }
}

export const config: ConfigType = {
    env: `${process.env.NODE_ENV}`,
    firebase: {
        apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
        authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
        projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
        storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
        messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
        appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
        measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
        databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    },
}
