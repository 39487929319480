import { Pad } from './string'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthsMin = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const daysMin = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
]

export const FormatDateMinShort = (value: Date) => (
  `${Pad(value.getDate(), 2)} ${
    monthsMin[value.getMonth()]
  } ${value.getFullYear()}`
)

export const FormatDateShort = (value: Date) => (
  `${Pad(value.getDate(), 2)} ${
    months[value.getMonth()]
  } ${value.getFullYear()}`
)

export const FormatDateLong = (value: Date) => (
  `${Pad(value.getDate(), 2)} ${
    months[value.getMonth()]
  } ${value.getFullYear()} ${Pad(value.getHours(), 2)}:${Pad(
    value.getMinutes(),
    2,
  )}:${Pad(value.getSeconds(), 2)}`
)

export const FormatDateMinLong = (value: Date) => (
  `${Pad(value.getDate(), 2)} ${
    monthsMin[value.getMonth()]
  } ${value.getFullYear()} ${Pad(value.getHours(), 2)}:${Pad(
    value.getMinutes(),
    2,
  )}:${Pad(value.getSeconds(), 2)}`
)

export const FormatMonthYear = (value: Date) => (
  `${months[value.getMonth()]} ${value.getFullYear()}`
)

export const Format = {
  DateMinShort: 'dd MMM yyyy',
  DateShort: 'dd MMMM yyyy',
  DateMinLong: 'dd MMM yyyy HH:mm:ss',
  DateLong: 'dd MMMM yyyy HH:mm:ss',
}

export const DateFormat = (value: Date, format: string): string => {
  let
    isLoop = true,
    year = true,
    month = true,
    day = true,
    hour = true,
    minute = true,
    second = true,
    ampm = true

  while (isLoop) {
    if (year) {
      if (format.includes('yyyy')) {
        const temp = value.getFullYear().toString()
        format = format.replace('yyyy', temp)
        year = false
        continue
      }

      if (format.includes('yy')) {
        const temp = value.getFullYear().toString().substr(2, 2)
        format = format.replace('yy', temp)
        year = false
        continue
      }
    }

    if (month) {
      if (format.includes('MMMM')) {
        const temp = months[value.getMonth()]
        format = format.replace('MMMM', temp)
        month = false
        continue
      }

      if (format.includes('MMM')) {
        const temp = monthsMin[value.getMonth()]
        format = format.replace('MMM', temp)
        month = false
        continue
      }

      if (format.includes('MM')) {
        const temp = Pad(value.getMonth() + 1, 2)
        format = format.replace('MM', temp)
        month = false
        continue
      }

      if (format.includes('M')) {
        const temp = (value.getMonth() + 1).toString()
        format = format.replace('M', temp)
        month = false
        continue
      }
    }

    if (day) {
      if (format.includes('dddd')) {
        const temp = days[value.getDay()]
        format = format.replace('dddd', temp)
        day = false
        continue
      }

      if (format.includes('ddd')) {
        const temp = daysMin[value.getDay()]
        format = format.replace('ddd', temp)
        day = false
        continue
      }

      if (format.includes('dd')) {
        const temp = Pad(value.getDate(), 2)
        format = format.replace('dd', temp)
        day = false
        continue
      }

      if (format.includes('d')) {
        const temp = value.getDate.toString()
        format = format.replace('d', temp)
        day = false
        continue
      }
    }

    if (hour) {
      if (format.includes('HH')) {
        const temp = Pad(value.getHours(), 2)
        format = format.replace('HH', temp)
        hour = false
        continue
      }

      if (format.includes('H')) {
        const temp = value.getHours().toString()
        format = format.replace('H', temp)
        hour = false
        continue
      }

      if (format.includes('hh')) {
        const temp = Pad(value.getHours() - 12, 2)
        format = format.replace('hh', temp)
        hour = false
        continue
      }

      if (format.includes('h')) {
        const temp = (value.getHours() - 12).toString()
        format = format.replace('h', temp)
        hour = false
        continue
      }
    }

    if (minute) {
      if (format.includes('mm')) {
        const temp = Pad(value.getMinutes(), 2)
        format = format.replace('mm', temp)
        minute = false
        continue
      }

      if (format.includes('m')) {
        const temp = value.getMinutes().toString()
        format = format.replace('m', temp)
        minute = false
        continue
      }
    }

    if (second) {
      if (format.includes('ss')) {
        const temp = Pad(value.getSeconds(), 2)
        format = format.replace('ss', temp)
        second = false
        continue
      }

      if (format.includes('s')) {
        const temp = value.getSeconds().toString()
        format = format.replace('s', temp)
        second = false
        continue
      }
    }

    if (ampm) {
      if (format.includes('tt')) {
        const temp = value.getHours() < 12 ? 'AM' : 'PM'
        format = format.replace('tt', temp)
        ampm = false
        continue
      }

      if (format.includes('t')) {
        const temp = value.getHours() < 12 ? 'A' : 'P'
        format = format.replace('t', temp)
        ampm = false
        continue
      }
    }

    isLoop = false
  }
  return format
}
