import { config } from '../config'

const DatabaseNode = {
    accounts: (uid: string) => `${config.env}/accounts/${uid}`,
    accountDetail: (uid: string, key: string) => `${config.env}/accounts/${uid}/${key}`,
    users: () => `${config.env}/users`,
    userDetail: (uid: string) => `${config.env}/users/${uid}`,
    history: (uid: string) => `${config.env}/history/${uid}`,
}

export default DatabaseNode
