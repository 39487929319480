import React, { FC } from "react";
import { Table, Button } from "react-bootstrap";

import { cssHistory } from "./style";

interface Props {
  list: any;
  handleDeleteData: (param: string) => void;
}

const TableComponent: FC<Props> = ({ list, handleDeleteData }) => (
  <Table bordered hover className={cssHistory}>
    <thead>
      <tr>
        <th>#</th>
        <th>Email</th>
        <th>Tindakan</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(list).length > 0 ? (
        <>
          {Object.keys(list).map((valKey, index) => {
            const value = list[valKey];

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{value.email}</td>
                <td>
                  <Button
                    variant="danger"
                    type="submit"
                    size="sm"
                    onClick={() => handleDeleteData(valKey)}
                  >
                    Hapus
                  </Button>
                </td>
              </tr>
            );
          })}
        </>
      ) : (
        <tr>
          <td colSpan={3}>Tidak ada data</td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default TableComponent;
