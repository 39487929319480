import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScreenPaths from "./screen-paths";
import Navbar from "../components/navbar";
import {
  LoginScreen,
  PageNotFoundScreen,
  RegisterScreen,
  Home,
  Scan,
  PrivacyPolicy,
} from "../screens";

type Props = {};

const AnonymousRouting: FC<Props> = () => {
  return (
    <>
      <Navbar.Anonymous />
      <Switch>
        <Route path={ScreenPaths.home} exact component={Home} />
        <Route path={ScreenPaths.login} component={LoginScreen} />
        <Route
          path={ScreenPaths.logout}
          component={() => <Redirect to={ScreenPaths.home} />}
        />
        <Route path={ScreenPaths.privacyPolicy} component={PrivacyPolicy} />
        <Route path={ScreenPaths.register} component={RegisterScreen} />
        <Route path={ScreenPaths.search} component={Scan} />
        <Route component={PageNotFoundScreen} />
      </Switch>
    </>
  );
};

export default AnonymousRouting;
