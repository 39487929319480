import { css } from "react-emotion";
import { mediaTo, mediaFrom, breakpoints } from "../../helpers/styles";
import imgHomeBG from "../../assets/def-bg.jpeg";

const { sm, md } = breakpoints;

export const cssTopHomeWrapper = css({
  minHeight: "100vh",
  backgroundColor: "#1d1133",
  backgroundImage: `url(${imgHomeBG})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  paddingTop: "5rem",
  [mediaFrom(md)]: { paddingTop: "10rem" },
});

export const cssBox = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  [mediaTo(sm)]: { textAlign: "left" },
  [mediaFrom(sm)]: { margin: "0px 1.5rem" },
});

export const cssTitle = css({
  maxWidth: 750,
  color: "#f9f9fa",
  fontSize: 28,
  fontWeight: 700,
  lineHeight: 1.14,
  margin: "0px auto 24px",
  [mediaFrom(md)]: { fontSize: 56 },
});

export const cssDesc = css({
  maxWidth: 560,
  color: "#f9f9fa",
  fontSize: 14,
  lineHeight: 1.17,
  margin: "0px auto 32px",
  [mediaFrom(sm)]: { textAlign: "center" },
  [mediaFrom(md)]: { fontSize: 24 },
});

export const cssFormWrapper = css({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [mediaFrom(sm)]: { width: 300, margin: "auto" },
});

export const cssFormFooterNotes = css({
  color: "#ededf0",
  margin: "12px auto 48px",
  maxWidth: 260,
  fontSize: 12,
  lineHeight: 1.5,
  textAlign: "center",
});

export const cssButton = css({
  fontSize: 12,
  width: "100%",
  fontWeight: 600,
  borderRadius: 8,
  background: "#ff9900 !important",
  borderColor: "#ff9900 !important",
  [mediaFrom(sm)]: {
    maxWidth: 360,
    fontSize: "16px !important",
  },
});

export const cssTextfield = (isValid: boolean): string =>
  css({
    color: "#20123a",
    fontSize: "14px !important",
    ...(!isValid && {
      border: "3px solid #b95252",
    }),
    [mediaFrom(sm)]: {
      fontSize: "18px !important",
    },
  });
