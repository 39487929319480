import { css } from "react-emotion";

export const cssPPWrapper = css({
  background: "#f9f7fd",
  paddingTop: "5rem",
  paddingBottom: 48,
});

export const cssContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "#000000",
  backgroundColor: "#fff",
  borderRadius: 8,
  padding: 20,
  a: {
    color: "#0d6efd",
    fontWeight: "bold",
    textDecoration: "none",
  },
});

export const cssTitle = css({
  fontSize: 32,
  marginBottom: 32,
});

export const cssSubTitle = css({
  margin: "24px 0px 4px !important",
});
