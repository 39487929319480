import firebase from 'firebase/app'
import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { ScreenPaths } from '../../routing'

type Props = {}

const LogoutScreen: FC<Props> = () => {
    const history = useHistory()

    useEffect(() => {
        firebase.auth().signOut()
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                history.replace(ScreenPaths.home, null)
            })
    }, [history])

    return (
        <h1 className="mt-5">Logout</h1>
    )
}

export default LogoutScreen
